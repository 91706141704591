import React, { useState } from 'react';
import { ContactFormContext } from '../../context/contactFormContext';

export default function ContactFormContextWrapper({ children }) {
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [givenNameKana, setGivenNameKana] = useState();
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [organizationDepartment, setOrganizationDepartment] = useState('');
  const [organizationTitle, setOrganizationTitle] = useState('');
  const [url, setUrl] = useState('');
  const [reason, setReason] = useState('none');
  const [body, setBody] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  return (
    <ContactFormContext.Provider
      value={{
        familyName: familyName,
        setFamilyName: setFamilyName,
        givenName: givenName,
        setGivenName: setGivenName,
        familyNameKana: familyNameKana,
        setFamilyNameKana: setFamilyNameKana,
        givenNameKana: givenNameKana,
        setGivenNameKana: setGivenNameKana,
        tel: tel,
        setTel: setTel,
        email: email,
        setEmail: setEmail,
        organization: organization,
        setOrganization: setOrganization,
        organizationDepartment: organizationDepartment,
        setOrganizationDepartment: setOrganizationDepartment,
        organizationTitle: organizationTitle,
        setOrganizationTitle: setOrganizationTitle,
        url: url,
        setUrl: setUrl,
        reason: reason,
        setReason: setReason,
        body: body,
        setBody: setBody,
        isAgree,
        setIsAgree,
      }}
    >
      {children}
    </ContactFormContext.Provider>
  );
}
